//*
var TPA = {
  direction:'up',

  init: function(){
    this.setupListeners();
    
    // var cookie = this.readCookie('layers');
    // if((cookie !== null && cookie == 'on') || cookie == null){
    //   $('.layers').trigger('click');
    // }

    this.lightUpMatrix();
    
  },

  setupListeners: function(){  
    // start hammer

    if(!$('body').hasClass('home') && !$('body').hasClass('gp')){
      var mc = new Hammer($('.cnext')[0]);
      // listen to events...
      mc.on("tap swipeleft", function(ev) {
        TPA.direction = 'up';
        TPA.showNextImg(ev);
        TPA.lightUpMatrix();
      });
      mc.on("swiperight", function(ev) {
        TPA.direction = 'down';
        TPA.showNextImg(ev);
        TPA.lightUpMatrix();
      });

      var mc2 = new Hammer($('.cprev')[0]);
      // listen to events...
      mc2.on("tap swiperight", function(ev) {
        TPA.direction = 'down';
        TPA.showNextImg(ev);
        TPA.lightUpMatrix();
      });
      mc2.on("swipeleft", function(ev) {
        TPA.direction = 'up';
        TPA.showNextImg(ev);
        TPA.lightUpMatrix();
      });

      // left right arrows
      $(document).keydown(function(e) {
        if (e.keyCode === 37) {
          TPA.direction = 'down';
          TPA.showNextImg(e);
          TPA.lightUpMatrix();
        }
        if(e.keyCode === 39){
          TPA.direction = 'up';
          TPA.showNextImg(e);
          TPA.lightUpMatrix();
        }
      });
      // end left right arrows

    }else{
      $('.intro').delay(2500).fadeOut();
    }

    // end hammer

    
    $('.layers').click(function(e){TPA.showHideLayers(e)});
    $('.info-btn').click(function(e){TPA.showHideInfo(e)});
    $('.f-project').click(function(e){TPA.showHideMProjectFilter(e)});
    $('.f-type').click(function(e){TPA.showHideMTypetFilter(e)});
    $('.f-theme').click(function(e){TPA.showHideMTypetFilter(e)});
  },
  

  lightUpMatrix: function(){
    // reset previous
    $('.highlighted').removeClass('highlighted');
    $('.proj-img.active').each(function(){
      var currProject = $(this).data('img-project');
      var currThemes = $(this).data('img-themes').split(','); // needs to be an array
      var currType = $(this).data('img-type');
      //console.log(currProject,currThemes,currType);

      var currProjectEl = $('*[data-project-slug="'+currProject+'"]');
      currProjectEl.addClass('highlighted');     
      
      $('*[data-type-slug="'+currType+'"]').addClass('highlighted');

      var currThemeTitle = '';
      $.each( currThemes, function( i, val ) {
        $('*[data-theme-slug="'+val+'"]').addClass('highlighted');
        currThemeTitle = $('*[data-theme-slug="'+val+'"]').html();
      });
      

      // mobile filter buttons have a label
      $('.f-project').html(currProjectEl.html());
      $('.f-theme').html(currThemeTitle);

      if(currProjectEl.hasClass('active') === true){
        $('.f-project').addClass('active');
      }

    })

    // look if we have a selected meta item, if so use this as label instead of a random theme
    if($('.m-meta').find('.active').html() !== undefined){
      $('.f-theme').html($('.m-meta').find('.active').html());
      $('.f-theme').addClass('active');
    }

    
    
  },


  resize: function(){
    var maxHeight = $(window).height() - 80;
    var maxWidth = $(window).width(); //$('.gallery').width();//
    if(maxWidth > 850){
      maxWidth = $(window).width() - $('.m-project').innerWidth() - $('.m-meta').innerWidth();
      //console.log(maxWidth,$(window).width(),$('.m-project').width(),$('.m-meta').width());  
    }
    
    $('.proj-img img').each(function(){

      var natWidth = $(this)[0].naturalWidth;
      var natHeight = $(this)[0].naturalHeight;
      var myHeight = $(this)[0].naturalHeight;//$(this).height();
      var myWidth = $(this)[0].naturalWidth;//$(this).width();
      var newWidth;
      var newHeight;
      // console.log(myHeight, maxHeight,myWidth, maxWidth)
      if(myHeight > maxHeight || myWidth > maxWidth){
        if ((myWidth > maxWidth) || (myHeight > maxHeight)) {
          if ((myWidth / maxWidth) > (myHeight / maxHeight)) {
            newWidth = maxWidth;
            newHeight = parseInt(myHeight / (myWidth / newWidth), 10);
            $(this).width(newWidth);
            $(this).height(newHeight);
          } else {
            newHeight = maxHeight;
            newWidth = parseInt(myWidth / (myHeight / newHeight), 10);
            $(this).width(newWidth);
            $(this).height(newHeight);
          }
          //$(this).parent().css('top','40px');
          var top = maxHeight/2 - newHeight/2 + 40;
          $(this).parent().css('top',top+'px');        
        }
      }else{
        //console.log('here')
        $(this).width('auto');
        $(this).height('auto');
        var top = maxHeight/2 - myHeight/2 + 40;
        $(this).parent().css('top',top+'px');
      }      
      
    });

    $('.proj-img video').each(function(){

      var natWidth = $(this)[0].videoWidth;
      var natHeight = $(this)[0].videoHeight;
      var myHeight = $(this)[0].videoHeight;//$(this).height();
      var myWidth = $(this)[0].videoWidth;//$(this).width();
      var newWidth;
      var newHeight;

      if(myHeight > maxHeight || myWidth > maxWidth){
        if ((myWidth > maxWidth) || (myHeight > maxHeight)) {
          if ((myWidth / maxWidth) > (myHeight / maxHeight)) {
            newWidth = maxWidth;
            newHeight = parseInt(myHeight / (myWidth / newWidth), 10);
            $(this).width(newWidth);
            $(this).height(newHeight);
          } else {
            newHeight = maxHeight;
            newWidth = parseInt(myWidth / (myHeight / newHeight), 10);
            $(this).width(newWidth);
            $(this).height(newHeight);
          }
          //$(this).parent().css('top','40px');
          var top = maxHeight/2 - newHeight/2 + 40;
          $(this).parent().css('top',top+'px');
        }
      }else{
        $(this).width('auto');
        $(this).height('auto');
        var top = maxHeight/2 - myHeight/2 + 40;
        $(this).parent().css('top',top+'px');
      }

    });

  },

  showNextImg: function(e) {
    //console.log('showNextImg');
    var me =  $('.proj-img.active');//$(e.currentTarget);
    var next;
    var total = $('.proj-img').length;
    var totalImg = $('.gallery .proj-img').length;
    var totalTxt = $('.txtHolder .proj-img').length;
    var isImg = me.hasClass('img');
    var isTxt = me.hasClass('txt');
    //console.log(isImg,isTxt);
    if(total > 1){
      if(TPA.direction == 'up') {
        next = me.next();

        if(!next.length){ // we are at the end of a cycle

          // if we are at the end of imgs, check if there is txt available
          if(isImg){
            if(totalTxt > 0){
              // jump to text
              next = $('.txtHolder').find('.proj-img');
            }else{
              // go to first image
              next = $('.gallery').find('.proj-img').first();  
            }
          }else{
            // jump back to the first img
            if(totalImg > 0){
              next = $('.gallery').find('.proj-img').first();  
            }else{
              next = $('.txtHolder').find('.proj-img').first();  
            }
          }
          
        }    
      }else{
        next = me.prev();
        if(!next.length){ // we are at the end of a cycle

          // if we are at the end of imgs, check if there is txt available
          if(isImg){
            if(totalTxt > 0){
              // jump to text
              next = $('.txtHolder').find('.proj-img').last();
            }else{
              next = $('.gallery').find('.proj-img').last();  
            }
          }else{
            if(totalImg > 0){
              // jump back to the last image
              next = $('.gallery').find('.proj-img').last();    
            }else{
              next = $('.txtHolder').find('.proj-img').last();
            }
            
          }
          
        }    
      }
      
      me.removeClass('active');//.addClass('visible');
      next.addClass('active');
      //TPA.resize();

      var myNr = next.data('img-index') +1;
      $('.gallery-pager').html(myNr + ' / ' + total);

      // load youtube if necessary
      $('.playbtn').hide();
      var player;
      if(next.hasClass('proj-yt')){
        //console.log('vid')
        var vidid = next.attr("data-vidid");
        TPA.activateYT(vidid)
      }
    }
  },

  activateYT: function(vidid){
    $('.playbtn').show();
    player = new YT.Player('player', {
      height: '315',
      width: '560',
      videoId: vidid,
      playerVars: {
        'enablejsapi':1,
        'controls':0,
        'modestbranding':1,
        'disablekb':1,
        'rel':0,
        'loop':1,
        'playlist':vidid,
        'autoplay':1
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
     function onPlayerReady(){
      console.log('onplayerready');
      player.playVideo();
      player.setVolume(0);
      $('.playbtn').click(function(){
        console.log('play', player.getPlayerState());
        if(player.getPlayerState() == 1){
            player.stopVideo();
        }else{
          player.playVideo();
        }


      });
      //window.setTimeout(function(){player.playVideo()},3000)
    };
    function onPlayerStateChange(){
      console.log('state change');
    };
  },


  showHideLayers: function(e) {
    var me = $(e.currentTarget);
    if(me.hasClass('on')){
      me.removeClass('on');
      this.createCookie('layers','off',30);
    }else{
      me.addClass('on');
      this.createCookie('layers','on',30);
    }
    if($('body').hasClass('showlayers')){
      $('body').removeClass('showlayers');
    }else{
      $('body').addClass('showlayers');
      TPA.resize();
    }
  },

  showHideInfo: function(e){
    if($('body').hasClass('showinfo')){
      $('body').removeClass('showinfo');
      $('.info-btn').html('Info');
    }else{
      $('body').addClass('showinfo');
      $('.info-btn').html('Foto\'s');
    }
  },

  showHideMProjectFilter: function(e){
    //$('.m-project').toggle();
    var me = $(e.currentTarget);
    if(me.hasClass('on')){
      me.removeClass('on');
      $('body').removeClass('m-project-open');
    }else{
      me.addClass('on');
      $('body').addClass('m-project-open');
      if($('body').hasClass('m-meta-open')){
        $('body').removeClass('m-meta-open');
        $('.f-theme').removeClass('on');
      }
    }
    TPA.togglePicNrs();
  },
  showHideMTypetFilter: function(e){
    //$('.m-meta').toggle();
    var me = $(e.currentTarget);
    if(me.hasClass('on')){
      me.removeClass('on');
      $('body').removeClass('m-meta-open');
    }else{
      me.addClass('on');
      $('body').addClass('m-meta-open');
      if($('body').hasClass('m-project-open')){
        $('body').removeClass('m-project-open');
        $('.f-project').removeClass('on');
      }
    }
    TPA.togglePicNrs();
  },
  

  togglePicNrs: function(){
    if($('body').hasClass('m-meta-open') || $('body').hasClass('m-project-open')){
      $('.gallery-pager').addClass('closed');
    }else{
      $('.gallery-pager').removeClass('closed');
    }
  },

  createCookie: function(name,value,days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      //date.setTime(date.getTime()+(60*100)); // short period for testing comment before going live
      var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
  },

  readCookie: function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },

}

$(document).ready(function(){
  
  window.addEventListener('resize', function() {
      //TPA.resize();
    }, false);

  TPA.init();  
  // window.setTimeout(function(){TPA.resize();},200)

  $('.gallery').imagesLoaded( function() {
    // images have loaded
    //TPA.resize();
  });

  if($('body').hasClass('newhome')){
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // check if first one is video and start if necessary
    if($('.gallery .active').hasClass('proj-yt')){
        //console.log('vid')
        window.onYouTubeIframeAPIReady = function()  {
          var vidid = $('.gallery .active').attr("data-vidid");
          TPA.activateYT(vidid)
        }
      }
  }

});



//*/
